<template>
  <div class="p-4 mt-3 mx-auto puntofarmaCard" style="max-width: 1200px" v-if="!showDone">
    <!-- <div class="d-flex justify-content-between">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'questionari' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Questionari</router-link
      >
    </div> -->
    <div v-if="survey && !complete" class="py-3 mt-4">
      <section class="mb-5">
        <h4 class="text-prim-grad-1 text-center  text-left pb-1">
          {{ survey.name }}
        </h4>
        <p class="text-justify text-center text-medium-grey ">
          {{ survey.description }}
        </p>
      </section>
      <div v-if="survey.info">
        <div v-if="!proceed">
          <p class="text-justify mt-2 fs-5 informativa__p">
            {{ survey.info }}
          </p>
          <b-form-checkbox class="text-font-light-grey pb-4 mb-3" name="data_consense" :value="true"
            :unchecked-value="false" v-model="consent" :state="consent == true">
            Ho letto l'informativa e do il mio consenso
          </b-form-checkbox>
          <b-button variant="primary primary_indo text-white fw-bolder spacing_1" :disabled="!consent"
            @click="proceed = true">Procedi</b-button>
        </div>
        <div v-else>
          <FillSurvey :survey="survey.template" :afteres="true" :hascore="survey.has_score" v-on:end-quest="handleFilledSurvey($event)" />
        </div>
      </div>
      <div v-else>
        <FillSurvey :survey="survey.template" :afteres="true" :hascore="survey.has_score" v-on:end-quest="handleFilledSurvey($event)" />
      </div>
    </div>
    <div v-else-if="complete">
      <h2 class="fw-bolder">Operazione completata.</h2>
      <router-link :to="{ name: 'agenda' }">
        <b-button variant="primary text-white fw-bolder  m-2">
          Vai all'agenda
        </b-button>
      </router-link>
    </div>
  </div>
  <div class="mt-5 puntofarmaCard p-4" v-else-if="showDone">
    <h5>Hai già compilato il questionario.</h5>
    <router-link :to="{ name: 'agenda' }">
      <b-button variant="primary spacing_1 m-2">
        Vai all'agenda
      </b-button>
    </router-link>
  </div>
</template>
<script>
import { supportService, cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FillSurvey from "@/components/utilities/FillSurvey.vue";
export default {
  name: "UserFillScoreSurvey",
  components: {
    FillSurvey,
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      hash: this.$route.params.quest,
      survey: null,
      consent: false,
      proceed: false,
      complete: false,
      suspendable: null,
      showDone: false,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      const self = this;
      supportService
        .getSurveyById(this.hash)
        .then(function (res) {
          self.survey = res.data.data;
          self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    handleFilledSurvey(form) {
      const temp = {
        info: this.survey.info,
        nome: this.survey.name,
        domande: form,
      };
      var formData = {
        response: JSON.stringify(temp),
        survey_id: this.survey.hashid,
      };
      if (this.$route.query.user) {
        formData = {
          response: JSON.stringify(temp),
          survey_id: this.survey.hashid,
          user_id: this.$route.query.user,
        };
      }
      const self = this;
      cittadinoService
        .patientStoreFilledSurvey(formData)
        .then(function (res) {
          self.complete = true;
          window.scrollTo(0, 0);
          self.successAlert("Questionario inviato correttamente.");
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile inviare il questionario")
        })
    },
  },
  created() {
    if (this.$route.params.quest) {
      this.hash = this.$route.params.quest;
      this.fetchData();
    }
  },
};
</script>